const Cookie = require("js-cookie");

const supportedLocales = JSON.parse(process.env.VUE_APP_I18N_SUPPORTED_LOCALE) || ['en'];
const defaultLocale = process.env.VUE_APP_I18N_DEFAULT_LOCALE || 'en';

const routes = [

	{
		path: '/cookie-policy',
		name: 'CookiePolicy',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/CookiePolicy.vue'),
		englishOnly: true
	},
	{
		path: '/privacy',
		name: 'PrivacyPolicy',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/PrivacyPolicy.vue'),
		englishOnly: true
	},
	// {
	// 	path: '/mobile/privacy',
	// 	name: 'PrivacyPolicyMobile',
	// 	sitemap: false,
	// 	component: () => import(/* webpackChunkName: "Admin" */ './pages/PrivacyPolicyMobile.vue'),
	// 	englishOnly: true
	// },
	{
		path: '/disclaimer',
		name: 'Disclaimer',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/Disclaimer.vue'),
		englishOnly: true
	},
	{
		path: '/terms-of-use',
		name: 'TermsOfUse',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/TermsOfUse.vue'),
		englishOnly: true
	},
	{
		path: '/referral-terms-conditions',
		name: 'ReferralTerms',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/ReferralTerms.vue'),
		englishOnly: true
	},
	{
		path: '/impressum',
		name: 'Impressum',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/Impressum.vue'),
		englishOnly: true
	},
	// {
	// 	path: '/roadmap',
	// 	beforeEnter() {
	// 		window.location = '/whats-new'
	// 	}
	// },
	{
		path: '/bug-bounty',
		name: 'SecurityBounty',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/SecurityBounty.vue'),
		englishOnly: true
	},
	// {
	// 	path: '/blog/:slug',
	// 	name: 'BlogPost',
	// 	sitemap: true,
	// 	component: () => import(/* webpackChunkName: "Admin" */ './pages/BlogPost.vue'),
	// 	englishOnly: true
	// },
	// {
	// 	path: '/blog/category/:slug',
	// 	name: 'Blog',
	// 	sitemap: true,
	// 	component: () => import(/* webpackChunkName: "Admin" */ './pages/Blog.vue'),
	// 	englishOnly: true
	// },
	{
		path: '/docs/morpher_whitepaper',
		beforeEnter() {
			window.location = 'https://www.morpher.com/docs/morpher_whitepaper.pdf'
		},
		sitemap: true,
		englishOnly: true
	},
	{
		path: '/docs/morpher_whitepaper_russian',
		beforeEnter() {
			window.location = 'https://www.morpher.com/docs/morpher_whitepaper_russian.pdf'
		},
		sitemap: true,
		englishOnly: true
	},
	{
		path: '/docs/morpher_whitepaper_chinese',
		beforeEnter() {
			window.location = 'https://www.morpher.com/docs/morpher_whitepaper_chinese.pdf'
		},
		sitemap: true,
		englishOnly: true
	},
	{
		path: '/kyc/:status',
		name: 'Kyc',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/Kyc.vue'),
		englishOnly: true
	},
	{
		path: '/hodl',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},
	{
		path: '/ape',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},	
	{
		path: '/r/*',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},
	{
		path: '/trade_better',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},
	{
		path: '/app_link',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},
	{
		path: '/app_link_ios',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},
	{
		path: '/app_link_android',
		name: 'MobileRedirect',
		sitemap: true,
		component: () => import(/* webpackChunkName: "Admin" */ './pages/MobileRedirect.vue'),
		englishOnly: true
	},	
	// {
	// 	path: '/whats-new',
	// 	name: 'Changelog',
	// 	sitemap: true,
	// 	component: () => import(/* webpackChunkName: "Admin" */ './pages/Changelog.vue'),
	// 	englishOnly: true
	// },	
	{
		name: 'Localization',
		path: '/:locale(' + supportedLocales.join('|') + ')?',
		component: {
			render: h => h('router-view')
		},
		children: [

			{
				path: '',
				name: 'Home',
				sitemap: true
			},
			{
				path: 'cookie-policy',
				name: 'CookiePolicy',
				sitemap: false,
			},
			{
				path: 'privacy',
				name: 'PrivacyPolicy',
				sitemap: false,
			},
			{
				path: 'disclaimer',
				name: 'Disclaimer',
				sitemap: false,
			},
			{
				path: 'terms-of-use',
				name: 'TermsOfUse',
				sitemap: false,
			},
			{
				path: 'impressum',
				name: 'Impressum',
				sitemap: false,
			},
			{
				path: 'bug-bounty',
				name: 'SecurityBounty',
				sitemap: false,
			},
			{
				path: 'about',
				name: 'About',
				sitemap: true,
			},
			{
				path: 'wallet',
				name: 'Wallet',
				sitemap: true,
			},
			{
				path: 'roadmap',
				name: 'Roadmap',
				sitemap: false,
			},
			{
				path: 'unsubscribe',
				name: 'Unsubscribe',
				sitemap: false
			},
			// {
			// 	path: '/stock/:slug',
			// 	name: 'StockPage',
			// 	sitemap: 'true'
			// },
			{
				path: 'login',
				beforeEnter() {
					window.location = process.env.VUE_APP_APPLICATION_URL + '/'
				},
				sitemap: true
			},
			
			{
				path: 'register',
				beforeEnter() {
					window.location = process.env.VUE_APP_APPLICATION_URL + '/register'
				},
				sitemap: true
			},
			{
				path: 'trade',
				beforeEnter() {
					window.location = process.env.VUE_APP_APPLICATION_URL
				},
				sitemap: true
			},
			// {
			// 	path: 'dex',
			// 	beforeEnter() {
			// 		window.location = process.env.VUE_APP_DEX_URL
			// 	},
			// 	sitemap: true
			// },
			// {
			// 	path: 'discord',
			// 	beforeEnter() {
			// 		window.location = 'https://discord.gg/CBxuAhPSCj'
			// 	},
			// 	sitemap: false
			// },
			{
				path: 'data-chain',
				beforeEnter() {
					window.location = 'https://www.morpher.com/data-chain'
				},
				sitemap: true
			},
			// {
			// 	path: 'vk',
			// 	beforeEnter() {
			// 		window.location = 'https://vk.com/morpher'
			// 	}
			// },
			{
				path: 'guide',
				alias: '/guide/*',
				beforeEnter() {
					window.location = 'https://support.morpher.com'
				},
				sitemap: false
			},
			{
				path: 'support',
				beforeEnter() {
					window.location = 'https://support.morpher.com'
				},
				sitemap: true
			},
			{
				path: 'ru/guides',
				beforeEnter() {
					window.location = 'https://support.morpher.com/ru/category/poleznye-rukovodstva-x5b7m0/'
				},
				sitemap: false
			},
			{
				path: 'ru/deposit-guide',
				beforeEnter() {
					window.location = 'https://support.morpher.com/ru/article/kak-popolnit-schet-s-rossijskoj-karty-1mpduwv/'
				},
				sitemap: false
			},
			{
				path: 'ru/invest-guide',
				beforeEnter() {
					window.location = 'https://support.morpher.com/ru/article/kak-investirovat-v-akcii-na-morpher-11si0dd/'
				},
				sitemap: false
			},
			{
				path: 'ru/withdraw-guide',
				beforeEnter() {
					window.location = 'https://support.morpher.com/ru/article/kak-vyvesti-sredstva-na-rossijskuyu-kartu-qwzv9g/'
				},
				sitemap: false
			},
			// {
			// 	path: 'affiliates',
			// 	beforeEnter() {
			// 		window.location = 'https://affiliates.morpher.com'
			// 	},
			// 	sitemap: true
			// },
			{
				path: 'scan',
				beforeEnter() {
					window.location = 'https://morpher.network'
				},
				sitemap: false
			},
			// {
			// 	path: 'arena',
			// 	beforeEnter() {
			// 		window.location = 'https://www.traderussianstocks.com'
			// 	},
			// 	sitemap: false
			// },
			{
				path: 'stats',
				beforeEnter() {
					window.location = 'https://www.morpher.com/stats'
				},
				sitemap: true
			},
			{
				path: 'docs/morpher_whitepaper',
				beforeEnter() {
					window.location = 'https://www.morpher.com/docs/morpher_whitepaper.pdf'
				},
				sitemap: false
			},
			{
				path: 'docs/morpher_whitepaper_russian',
				beforeEnter() {
					window.location = 'https://www.morpher.com/docs/morpher_whitepaper_russian.pdf'
				},
				sitemap: false
			},
			{
				path: 'docs/morpher_whitepaper_chinese',
				beforeEnter() {
					window.location = 'https://www.morpher.com/docs/morpher_whitepaper_chinese.pdf'
				},
				sitemap: false
			},
			{
				path: 'invite',
				beforeEnter() {
					window.location = '/'
				}
			},
			{
				path: 'invite/:referred_by',
				name: 'Rewards',
				sitemap: false
			},
			// {
			// 	path: 'blog',
			// 	name: 'Blog',
			// 	sitemap: true
			// },
			// {
			// 	path: 'blog/category/:slug',
			// 	name: 'Blog',
			// 	sitemap: false
			// },
			// {
			// 	path: 'blog/:slug',
			// 	name: 'BlogPost',
			// 	sitemap: false,
			// },
			//   {
			//   	name: 'Main',
			// 	  path: '/:lang',
			// 	  children: [
			// 		  {
			// 			  path: 'trade/fitbit',
			// 			  name: 'fitbit',
			// 			  sitemap: false
			// 		  },
			// 		  {
			// 			  path: 'trade/movers',
			// 			  name: 'movers',
			// 			  sitemap: false
			// 		  },
			// 		  {
			// 			  path: '',
			// 			  name: 'Home',
			// 			  sitemap: true
			// 		  }
			// 		],
			// 	  translated: true
			//   },
			{
				path: '404',
				name: '404'
			},
			{
				path: ':custom_invite',
				name: 'Rewards'
			},
			{
				path: '*',
				redirect: '/404'
			}
		],
	},
	{
		path: '*',
		redirect() {
			return Cookie.get('locale') || defaultLocale;
		}
	}
];

const page_list = require("./custom/page_list");


page_list.forEach(page => {
	const Localization = routes.findIndex(route => route.name === 'Localization' )
	
	 
	//  routes[Localization].children.unshift({
	//  	path: '/ru/' + page[0],
	//  	name: 'CustomPage',
	//  	sitemap: true,
	//  	component: () => import(/* webpackChunkName: "Admin" */ './custom/custom.vue'),
	//  	englishOnly: true
	//  })

	routes.unshift({
		path: page[0],
		name: 'CustomPage',
		sitemap: false,
		component: () => import(/* webpackChunkName: "Admin" */ './custom/custom.vue'),
		englishOnly: true
	})
	
})


module.exports = routes;