<template>
	<div id="app">
		<MobileAppBanner />

		<div v-if="showLocaleNotify()" class="lang-notify">
			<span
				v-html="
					$t('SWITCH_LANG_NOTIFY', {
						browserLang: getLanguageParameter(
							browserLocale ? browserLocale.split('-')[0] : '',
							'name'
						),
					})
				"
			/>
			<a class="switch-button" @click="switchLanguage()">{{
				$t("SWITCH_NOW", {
					browserLangFlag: getLanguageParameter(
						browserLocale ? browserLocale.split("-")[0] : "",
						"flag"
					),
					browserLang: getLanguageParameter(
						browserLocale ? browserLocale.split("-")[0] : "",
						"name"
					),
				})
			}}</a>
		</div>
		<router-view />
		<CookieBanner />
	</div>
</template>
<script>
import MobileAppBanner from "@/components/app/MobileAppBanner.vue";
import CookieBanner from "@/components/partials/CookieBanner.vue";
import Cookie from "js-cookie";
import { getLanguageParameter } from "./main";

import markets from "./services/Markets";
const axios = require("axios");

export default {
	name: "App",
	components: {
		CookieBanner,
		MobileAppBanner,
	},
	data: () => {
		return {
			affiliateSaved: true,
			browserLocale:
				(navigator.languages && navigator.languages[0]) ||
				navigator.language ||
				"",
		};
	},
	watch: {
		"$route.query"(nv) {
			const mclid = this.$route.query.mclid;
			if (mclid) {
				markets.emailClick(mclid)
			}
			const utm_source = this.$route.query.utm_source;
			const utm_medium = this.$route.query.utm_medium;
			let utm_campaign = this.$route.query.utm_campaign;
			const utm_term = this.$route.query.utm_term;
			const utm_content = this.$route.query.utm_content;
			if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
				localStorage.setItem("utm_source", utm_source || "");
				localStorage.setItem("utm_medium", utm_medium || "");
				if (this.$route.query.at_gd && !utm_campaign) {
					utm_campaign = "adtraction";
				}
				localStorage.setItem("utm_campaign", utm_campaign || "");
				localStorage.setItem("utm_term", utm_term || "");
				localStorage.setItem("utm_content", utm_content || "");
			}

			var referrer = document.referrer;
			if (referrer) {
				localStorage.setItem("utm_referrer", referrer);
			}

			if (this.$route.query.aff_id) {
				const affiliate_id = localStorage.getItem("affiliate_id");
				if (
					!affiliate_id ||
					affiliate_id.toLowerCase() !== this.$route.query.aff_id.toLowerCase()
				) {
					this.affiliateSaved = false;
					localStorage.setItem("affiliate_id", this.$route.query.aff_id);
					localStorage.setItem(
						"affiliate_campaign",
						this.$route.query.campaign || ""
					);
					localStorage.setItem("affiliate_referrer", document.referrer || "");
					localStorage.setItem("affiliate_utm_source", utm_source || "");
					localStorage.setItem("affiliate_utm_medium", utm_medium || "");
					localStorage.setItem("affiliate_utm_campaign", utm_campaign || "");
					localStorage.setItem("affiliate_path", this.$route.path);
				}
			}

			if (this.$route.query.at_gd) {
				const at_gd = localStorage.getItem("at_gd");
				if (
					!at_gd ||
					at_gd.toLowerCase() !== this.$route.query.at_gd.toLowerCase()
				) {
					this.affiliateSaved = false;
					localStorage.setItem("at_gd", this.$route.query.at_gd);
					localStorage.setItem(
						"affiliate_campaign",
						this.$route.query.campaign || ""
					);
					localStorage.setItem("affiliate_referrer", document.referrer || "");
					localStorage.setItem("affiliate_utm_source", utm_source || "");
					localStorage.setItem("affiliate_utm_medium", utm_medium || "");
					localStorage.setItem("affiliate_utm_campaign", utm_campaign || "");
					localStorage.setItem("affiliate_path", this.$route.path);
				}
			}
			if (this.$route.query.ref) {
				const ref = localStorage.getItem("ref");
				if (!ref || ref.toLowerCase() !== this.$route.query.ref.toLowerCase()) {
					this.affiliateSaved = false;
					localStorage.setItem("ref", this.$route.query.ref);
					if (this.$route.query.tap_s)
						localStorage.setItem("source_id", this.$route.query.tap_s);
					localStorage.setItem("affiliate_referrer", document.referrer || "");
				}
			}

			this.saveAffiliateData();
		},
	},
	created() {
		// Identify FullStory user as development if one of these hostnames
		// const devEnvironments = ["localhost", "127.0.0.1"];
		// const currentHost = window.location.hostname;
		// if (
		// 	devEnvironments.indexOf(currentHost) > -1 ||
		// 	currentHost.includes("morpher.netlify.com") ||
		// 	currentHost.includes("test.morpher.com")
		// ) {
		// 	FS.identify("development_env");
		// }
	},
	mounted() {
		// Hide Crisp Chat
		// $crisp.push(["do", "chat:hide"]);

		const mclid = this.$route.query.mclid;
		if (mclid) {
			markets.emailClick(mclid)
		}
		const utm_source = this.$route.query.utm_source;
		const utm_medium = this.$route.query.utm_medium;
		let utm_campaign = this.$route.query.utm_campaign;
		const utm_term = this.$route.query.utm_term;
		const utm_content = this.$route.query.utm_content;
		if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
			localStorage.setItem("utm_source", utm_source || "");
			localStorage.setItem("utm_medium", utm_medium || "");
			localStorage.setItem("utm_campaign", utm_campaign || "");
			if (this.$route.query.at_gd && !utm_campaign) {
				utm_campaign = "adtraction";
			}

			localStorage.setItem("utm_term", utm_term || "");
			localStorage.setItem("utm_content", utm_content || "");
		}

		var referrer = document.referrer;
		if (referrer) {
			localStorage.setItem("utm_referrer", referrer);
		}
		if (this.$route.query.aff_id) {
			const affiliate_id = localStorage.getItem("affiliate_id");
			if (
				!affiliate_id ||
				affiliate_id.toLowerCase() !== this.$route.query.aff_id.toLowerCase()
			) {
				this.affiliateSaved = false;
				localStorage.setItem("affiliate_id", this.$route.query.aff_id);
				localStorage.setItem(
					"affiliate_campaign",
					this.$route.query.campaign || ""
				);
				localStorage.setItem("affiliate_referrer", document.referrer || "");
				localStorage.setItem("affiliate_utm_source", utm_source || "");
				localStorage.setItem("affiliate_utm_medium", utm_medium || "");
				localStorage.setItem("affiliate_utm_campaign", utm_campaign || "");
				localStorage.setItem("affiliate_path", this.$route.path);
				localStorage.setItem("at_gd", this.$route.query.at_gd);
			}
		}

		if (this.$route.query.at_gd) {
			const at_gd = localStorage.getItem("at_gd");
			if (
				!at_gd ||
				at_gd.toLowerCase() !== this.$route.query.at_gd.toLowerCase()
			) {
				this.affiliateSaved = false;
				localStorage.setItem("affiliate_id", this.$route.query.aff_id);
				localStorage.setItem("at_gd", this.$route.query.at_gd);
				localStorage.setItem(
					"affiliate_campaign",
					this.$route.query.campaign || ""
				);
				localStorage.setItem("affiliate_referrer", document.referrer || "");
				localStorage.setItem("affiliate_utm_source", utm_source || "");
				localStorage.setItem("affiliate_utm_medium", utm_medium || "");
				localStorage.setItem("affiliate_utm_campaign", utm_campaign || "");
				localStorage.setItem("affiliate_path", this.$route.path);
			}
		}
		this.saveAffiliateData();
	},
	methods: {
		getLanguageParameter(code, parameter) {
			return getLanguageParameter(code, parameter);
		},
		showLocaleNotify() {
			const lang = (
				(this.browserLocale ? this.browserLocale.split("-")[0] : "") || ""
			).toUpperCase();
			const supportedLocales = JSON.parse(
				process.env.VUE_APP_I18N_SUPPORTED_LOCALE
			) || ["en"];
			if (!supportedLocales.includes(lang.toLowerCase())) return false;
			const isInviteRoute =
				this.$route.name === "Airdrop" && this.$route.params.custom_invite;
			return lang !== this.$i18n.locale.toUpperCase() && !isInviteRoute;
		},
		saveAffiliateData() {
			if (!this.affiliateSaved) {
				this.affiliateSaved = true;

				const affiliate_id = localStorage.getItem("affiliate_id");
				const at_gd = localStorage.getItem("at_gd");
				const ref = localStorage.getItem("ref");
				const affiliate_campaign =
					localStorage.getItem("affiliate_campaign") || "";
				const affiliate_referrer =
					localStorage.getItem("affiliate_referrer") || "";
				const affiliate_utm_source =
					localStorage.getItem("affiliate_utm_source") || "";
				const affiliate_utm_medium =
					localStorage.getItem("affiliate_utm_medium") || "";
				const affiliate_utm_campaign =
					localStorage.getItem("affiliate_utm_campaign") || "";
				const affiliate_path = localStorage.getItem("affiliate_path") || "";

				if (ref) {
					axios
						.post("/web/sendAnalytics", {
							event: "tapfiliate_click",
							data: {
								ref,
								source_id: localStorage.getItem("source_id"),
								referrer: affiliate_referrer,
								landing_page: window.location.href,
								user_agent: window.navigator.userAgent,
							},
						})
						.then(() => {
							this.affiliateSaved = true;
						});
				}

				if (affiliate_id || at_gd) {
					axios
						.post("/web/sendAnalytics", {
							event: "affiliate_data",
							data: {
								affiliate_id,
								affiliate_campaign,
								affiliate_referrer,
								affiliate_utm_source,
								affiliate_utm_medium,
								affiliate_utm_campaign,
								affiliate_path,
								at_gd,
								ref,
							},
						})
						.then(() => {
							this.affiliateSaved = true;
						});
				}
			}
		},
		switchLanguage() {
			const lang = (
				(this.browserLocale ? this.browserLocale.split("-")[0] : "") || ""
			).toUpperCase();
			if (lang && lang !== this.$i18n.locale) {
				const path =
					(lang.toLowerCase() !== "en" ? "/" + lang.toLowerCase() : "") +
					location.pathname.replace("/" + this.$i18n.locale.toLowerCase(), "");

				this.$i18n.locale = lang;
				document.querySelector("html")?.setAttribute("lang", lang);
				if (lang === "ar")
					document.querySelector("html")?.setAttribute("dir", "rtl");
				else document.querySelector("html")?.setAttribute("dir", "");
				Cookie.set("locale", lang);

				const baseURL = process.env.BASE_URL
					? process.env.BASE_URL === "/"
						? ""
						: process.env.BASE_URL
					: "";

				const address =
					location.protocol + "//" + location.host + baseURL + path;
				window.location.replace(address);
			}
		},
	},
};
</script>
<style lang="scss">
@import url("./assets/styles/main.css");

// Custom variables
$primary: #00c386;
$table-background-color: none;
$table-cell-border: solid hsl(0, 0%, 88%);
$table-cell-border-width: 0 0 1.25px;
$table-cell-padding: 0.75em 0.75em;
$table-head-cell-border-width: 0;
$table-head-cell-color: #6e6e6e;
$table-row-active-background-color: none;
$table-row-active-color: none;
$table-striped-row-even-background-color: none;
$table-row-hover-background-color: hsl(0, 0%, 96%);
$table-striped-row-even-hover-background-color: $table-row-hover-background-color;

$tag-background-color: #f7faf9;
$tag-radius: 5px;

$navbar-dropdown-item-hover-background-color: none;
$navbar-item-hover-background-color: none;
$navbar-dropdown-arrow: #666;
$navbar-breakpoint: 768px;

@import "~bulma";
@import "~buefy/src/scss/buefy";

.lang-notify {
	padding: 10px;
	font-size: 13px;
	background: #fff;
	text-align: center;
	z-index: 1;
	position: relative;
}

.switch-button {
	margin-left: 3px;
}
</style>
